import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";


const useStyles = makeStyles(theme => ({
  cardButton: {
    display: "block",
    width: "100%"
  },
  image: {
    height: "auto",
    width: "auto",
    maxWidth: "100%",
    maxHeight: "100%"
  },
  imageSelectorPlaceholder: {
    height: 180,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

export default function ImageSelector(props) {
  const classes = useStyles();

  const { children, onSelected } = props;

  const [selectedImage, setSelectedImage] = useState();

  let fileSelectorInput = useRef(null);;

  const handleOpenFileSelector = () => {
    fileSelectorInput.current.click();
  }

  const fileChangedHandler = async (event) => {
    const { files } = event.target;
    if (!files.length) return;
    
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = function(e) {
        setSelectedImage(e.target.result);
        onSelected(e.target.result);
    };
  }

  let content;
  if (selectedImage)
    content = (
      <div
        className={classes.imageSelectorPlaceholder}
      >
        <img className={classes.image} src={selectedImage} alt="" />
      </div>
    );
  else
    content = (
      <div
        className={classes.imageSelectorPlaceholder}
      >
        {children}
      </div>
    );

  return (
    <>
      <input
        accept="image/*"
        style={{ display: "none" }}
        type="file"
        ref={fileSelectorInput}
        onChange={evt =>
          fileChangedHandler(evt)
        }
      />
      <ListItem button onClick={handleOpenFileSelector} className={classes.cardButton}>
        {content}
      </ListItem>
    </>
  );
}
